<section class="content">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
        [class.active]="i===breadcrumbList.length-1">
      <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
        <b>{{ item.name }}</b>
      </a>
      <span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
    </li>
  </ol>
  <div class="form-group row">
    <div class="col-sm-12">
      <div class=" panel-default">
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-body">
                <div>
                  <form id="createSeaFormId" class="form-horizontal" [formGroup]="candidateForm"
                        (ngSubmit)="saveCrew()"
                        #candform="ngForm">
                    <div class="panel panel-purple" style="padding-bottom: 10px;">
                      <div class="panel-heading">
                        Candidate Information
                      </div>
                      <div class="panel-body form-group mb0 pb0">
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label"><span style="color: #b40a09;">*</span>First Name:</label>
                          <input id="fNameId" type="text" name="f_name" formControlName="f_name"
                                 placeholder="First Name"
                                 class="form-control"/>
                          <small
                            *ngIf="f.f_name.invalid && (candform.submitted || f.f_name.touched || f.f_name.dirty)"
                            style="color: maroon;">
											<span *ngIf="f.f_name.errors.required">
												Please enter something.
											</span>
                          </small>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label"><span style="color: #b40a09;">*</span>Last Name:</label>
                          <input id="lNameId" type="text" name="l_name" formControlName="l_name"
                                 placeholder="Last Name"
                                 class="form-control"/>
                          <small
                            *ngIf="f.l_name.invalid && (candform.submitted || f.l_name.touched || f.l_name.dirty)"
                            style="color: maroon;">
											<span *ngIf="f.l_name.errors.required">
												Please enter something.
											</span>
                          </small>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Age</label>
                          <div>
                            <input type="text" id="age" name="age" formControlName="age"
                                   placeholder="Age" class="form-control"/>
                            <small *ngIf="f.age.invalid && (candform.submitted ||  f.age.dirty)"
                                   style="color: maroon;">
                            <span *ngIf="f.age.errors.pattern">
                              Please enter valid age!
                            </span>
                              <span *ngIf="f.age.errors.minlength">
												        Please enter min 1 characters!
											        </span>
                              <span *ngIf="f.age.errors.maxlength">
												        Please enter max 3 characters!
											        </span>
                            </small>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label"><span style="color: #b40a09;">*</span>Gender:</label><br>
                          <mat-radio-group formControlName="gender">
                            <mat-radio-button color="primary" value="Male" id="radMale">Male</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button color="primary" value="Female" id="radFemale">Female</mat-radio-button>
                          </mat-radio-group>
                          <br>
                          <small *ngIf="f.gender.invalid && (candform.submitted || f.gender.touched || f.gender.dirty)"
                                 style="color: maroon;">
											<span *ngIf="f.gender.errors.required">
												Please select gender.
											</span>
                          </small>
                        </div>
                      </div>

                      <div class="panel-body form-group mb0 pb0">
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Email Id:</label>
                          <input type="email" id="emailId" name="emailId" formControlName="emailId"
                                 placeholder="Email Id" class="form-control"/>
                          <small *ngIf="f.emailId.invalid && (candform.submitted ||  f.emailId.dirty)"
                                 style="color: maroon;">
                      <span *ngIf="f.emailId.errors.pattern">
												Please enter valid email!
											</span>
                          </small>
                          <small *ngIf="f.emailId.errors?.email" style="color: maroon;">
											<span>
												Please enter valid email!
											</span>

                          </small>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="col-sm-12 pl0 pr0">Number :</label>
                          <div class="col-sm-4 pl0 pr0">
                            <input type="text" id="code" name="code" formControlName="code" placeholder="+91"
                                   class="form-control"/>
                            <small *ngIf="f.code.invalid && (candform.submitted ||  f.code.dirty)"
                                   style="color: maroon;">
                      <span *ngIf="f.code.errors.pattern">
												Please enter valid code!
											</span>
                            </small>
                          </div>
                          <div class="col-sm-8 pl0 pr0">
                            <input type="text" id="telephone" name="telephone" formControlName="telephone"
                                   placeholder="Number" class="form-control"/>
                            <small *ngIf="f.telephone.invalid && (candform.submitted ||  f.telephone.dirty)"
                                   style="color: maroon;">
                      <span *ngIf="f.telephone.errors.pattern">
												Please enter valid telephone!
											</span>
                              <span *ngIf="f.telephone.errors.minlength">
												Please enter min 6 characters!
											</span>
                              <span *ngIf="f.telephone.errors.maxlength">
												Please enter max 10 characters!
											</span>
                            </small>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Location:</label>
                          <input id="location" type="text" name="location" formControlName="location"
                                 placeholder="Location"
                                 class="form-control"/>
                        </div>


                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Relation:</label>
                          <ng-select id="relationToSeafarer" [items]="relationList" bindLabel="relation"
                                     bindValue="id"
                                     placeholder="Select Relation" name="relationToSeafarer"
                                     formControlName="relationToSeafarer">
                            <ng-template ng-label-tmp let-item="item">
                              {{item.relation}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                              {{item.relation}}
                            </ng-template>
                          </ng-select>
                        </div>


                      </div>
                      <div class="panel-body form-group mb0 pb0">
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Company:</label>
                          <input type="text" placeholder="Company" class="form-control" matInput id="company"
                                 formControlName="compId" [matAutocomplete]="comapnyautocom">
                          <mat-autocomplete #comapnyautocom="matAutocomplete" [displayWith]="getCompanyText"
                                            id="companyautocom">
                            <mat-option *ngFor="let option of filteredCompany | async" [value]="option">
                              {{option.comp_name}}
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Purpose:</label>
                          <ng-select id="purposeId" [items]="purposeList" bindLabel="purpose" bindValue="id"
                                     placeholder="Select Purpose"
                                     formControlName="purpose">
                            <ng-template ng-label-tmp let-item="item">
                              {{item.purpose}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                              {{item.purpose}}
                            </ng-template>
                          </ng-select>
                        </div>


                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Organization:</label>
                          <input id="organization" type="text" name="organization" formControlName="organization"
                                 placeholder="Organization"
                                 class="form-control"/>
                        </div>

                        <div class="col-lg-3 col-sm-3">
                          <label class="control-label">Received On:</label>
                          <ng-select id="receiveId" [items]="receiveList" bindLabel="mode" bindValue="id"
                                     placeholder="Select Receive On" name="relationToSeafarer"
                                     formControlName="receivedOn">
                            <ng-template ng-label-tmp let-item="item">
                              {{item.mode}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                              {{item.mode}}
                            </ng-template>
                          </ng-select>
                        </div>


                      </div>

                    </div>
                    <div align="center" class="panel-body">
                      <button id="seafSubId" [disabled]="showLoader" type="submit"
                              class="mr mb-sm btn btn-sm btn-success"><span style="margin-right: 5px;"><i
                        *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i><i
                        *ngIf="!showLoader"
                        class="fa fa-save"></i></span>Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
