import { Component, OnInit, OnDestroy } from '@angular/core';
import {ApiService} from '../api.service';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {

  eventlist:any = [];
  isListFinished :boolean = false;
  isLoading :boolean = false;
  tableparams = {
    search:"",
    page:0,
    limit:20,
    category:"Open",
    flag:'',
    column:"",
    sortType:""
  };
  notificationId:any;
  searchControl = new FormControl();
  formCtrlSub: Subscription;
  deleteApptId:any = null;
  deleteApptIndex:any = null;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      if(routeParams["search"]!=undefined){
        this.tableparams.search = routeParams["search"];
        this.tableparams.page = 0;
        this.isListFinished = false;
        this.eventlist = [];
      }
      this.getEventList();
    });
  }

  ngAfterViewInit(): void {
    this.formCtrlSub = this.searchControl.valueChanges
      .pipe(debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((newValue:any) => {
        if(newValue){
          this.router.navigate(['/app/appointmentsList',newValue]);
        }else{
          this.router.navigate(['/app/appointmentsList']);
        }
      });
  }

  getEventList(){
    if(!this.isListFinished && !this.isLoading){
      let params = {
        limit:this.tableparams.limit,
        page:this.tableparams.page,
        search:this.tableparams.search
      }


      this.isLoading = true;
      this.apiService.eventList(params).subscribe(
        (data:any) => {
          this.isLoading = false;
          let events :any = data;
          if(events.length==this.tableparams.limit){
            this.tableparams.page += 1;
          }else{
            this.tableparams.page += 1;
            this.isListFinished = true;
          }
          this.eventlist = this.eventlist.concat(events);
        },
        (error)=>{
          this.isLoading = false;
          this.isListFinished = true;
        }
      );
    }
  }

  fixDate(dateString:any){
		var date =  new Date(dateString.replace(/-/g, "/"));
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var day = date.getDate();
		var month = monthNames[date.getMonth()];
		var year = date.getFullYear();
		var hour = date.getHours();
		var minutes = date.getMinutes()+"";
		var seconds = date.getSeconds();
		var ampm = hour >= 12 ? 'pm' : 'am';
		hour = hour % 12;
		hour = hour ? hour : 12;
		minutes = parseInt(minutes) < 10 ? '0'+minutes : minutes;
		var strTime = hour + ':' + minutes + ' ' + ampm;

		day = this.checkZero(day);
		month = this.checkZero(month);
		hour = this.checkZero(hour);
		minutes = this.checkZero(minutes);
		seconds = this.checkZero(seconds);

		let dateStr = day + ", " + month + " " + year + " " + strTime;
		return dateStr;
	}

  checkZero(data:any){
    if(data != undefined && data.toString().length == 1){
			data = "0" + data;
		}
		return data;
  }

  deleteApptModal(userId:any,index:any){
    this.deleteApptId = userId;
    this.deleteApptIndex = index;
    Swal.fire({
      title: 'Warning !!!',
      text: 'Are you sure you want to remove appointment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {
        this.apiService.removePOC(this.deleteApptId).subscribe(
          data => {
            this.eventlist.splice(this.deleteApptIndex,1);
            Swal.fire(
              'Success',
              'Appointment Removed Successfully.',
              'success'
            )
          },
          (error)=>{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!'
            })
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.clearDelete();
      }
    })
  }

  clearDelete(){
    this.deleteApptId = null;
    this.deleteApptIndex = null;
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }

}
