import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  general_url = environment.API_GENERAL_URL;
  baseURL = environment.API_GENERAL_URL+"api/";

  private followUpRefresh = new Subject<any>();
  sendFollowUpRefreshEvent() {
    this.followUpRefresh.next();
  }
  getFollowUpRefreshEvent(): Observable<any>{
    return this.followUpRefresh.asObservable();
  }

  public loginAPI(user:any){
    var data = {email:user.email,password:user.password}
    return this.httpClient.post(this.baseURL+"auth/login",data);
  }


  public eventList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"appointment",options);
  }

  public companyList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"companyList",options);
  }

  public userRelationList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"userRelationList",options);
  }

  public ticketReceivedList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"ticketModeList",options);
  }

  public ticketPurposeList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"purposeList",options);
  }

  public createCustomEvent(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"appointment",data,{headers:headers});
  }

  public getBasicData(id:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"appointment/"+id,options);
  }

  public updateEvent(data:any,id:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.put(this.baseURL+"appointment/"+id,data,{headers:headers});
  }

  public removePOC(id:any){

    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.delete(this.baseURL+"appointment/"+id,{headers:headers});
  }

  public rebookEvent(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"rebookAppointment",data,{headers:headers});
  }

  public ticketStatusList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"ticketStatusList",options);
  }

  public statusToAssign(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"changeCaseStatus",data,{headers:headers});
  }

  public casePurpose(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"saveCasePurpose",data,{headers:headers});
  }

  public caseNotes(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"saveCaseNotes",data,{headers:headers});
  }

  public getApptDetailsData(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"getApptDetails",options);
  }

  public saveCaseFollowData(data:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    return this.httpClient.post(this.baseURL+"saveCaseFollowUpData",data,{headers:headers});
  }

  public userLogOut(){
    var data ={};
    var headers = {
      'Authorization':localStorage.getItem('tokenKey')
    }
    return this.httpClient.post(this.baseURL+"auth/logout",data,{headers:headers});
  }

  public caseFollowUpList(params:any){
    var headers = {
      'Authorization':localStorage.getItem('tokenKey'),
      'Content-Type':'application/json',
      'Accept':'application/json'
    }
    const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
    const options = { params: new HttpParams(httpParams), headers: headers };
    return this.httpClient.get(this.baseURL+"caseFollowUpList",options);
  }

  public getAgoraToken(channelName:any){
    // var data ={channelName:channelName};
    // var headers = {
    //   'Authorization':localStorage.getItem('tokenKey'),
    //   'Content-Type':'application/json',
    //   'Accept':'application/json'
    // }
    // return this.httpClient.post(this.baseURL+"agora/token",data,{headers:headers});

		const formData = new FormData();
		formData.append('channelName', channelName);
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization",localStorage.getItem('tokenKey'));
		return this.httpClient.post(this.baseURL+"agora/token", formData, { headers: _headers });
	}

	public getAgoraTokenOpen(channelName:any){
		const formData = new FormData();
		formData.append('channelName', channelName);
		return this.httpClient.post(this.baseURL+"agora/openToken", formData);
	}

  public chatFileUpload(file:any){
		const formData = new FormData();
		formData.append('file', file);
		return this.httpClient.post(this.baseURL+"fileUpload", formData);
	}
}


export interface HttpParamsOptions {
  fromString?: string;
  fromObject?: {
    [param: string]: string | string[];
  };
  encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
  encodeKey(key: string): string
  encodeValue(value: string): string
  decodeKey(key: string): string
  decodeValue(value: string): string
}

