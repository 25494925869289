import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import {  FolderFilterPipe }   from './shared/pipes/folder-filter.pipe';
import {  MyFilterPipe }   from './shared/pipes/my-filter.pipe';
import {  FilterUniqueNamePipe }   from './shared/pipes/unique-name.pipe';
import {TruncatePipe} from './app.pipe';

import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ReversePipe } from './reverse.pipe';
import {SafeResourcePipe} from './safe-resource.pipe';


@NgModule({
	declarations: [
		SafeHtmlPipe,
		FolderFilterPipe,
		InfiniteScrollComponent,
		TruncatePipe,
		MyFilterPipe,
		ReversePipe,
		FilterUniqueNamePipe,
    SafeResourcePipe
	],
	imports: [
		CommonModule
	],
	exports: [
		SafeHtmlPipe,
		FolderFilterPipe,
		InfiniteScrollComponent,
		TruncatePipe,
		MyFilterPipe,
		ReversePipe,
		FilterUniqueNamePipe,
    SafeResourcePipe
	]
})
export class SharedPipesModule { }
