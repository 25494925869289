import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { ButtonModel } from '@syncfusion/ej2-buttons';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query, DataManager,Predicate } from '@syncfusion/ej2-data';
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService]
})
export class EventDetailsComponent implements OnInit {

  breadcrumbList: any[] = [];
  assignCaseStatus:any = null;
  ticketStatusList: object = [];
  assignLoader:boolean =false;
  eventId:any = null;
  ticket_no:any = null;
  videoCallLink:any = null;
  videoCallLinkTextId:any = "videoCallLinkText";
  basicData:any=null;
  apttData:any=null;
  charLimit = 200;
  eventsSummery:any={notes:null,purpose:null};
  purposeLoader=false;
  notesLoader=false;
  casePurposeTextList: any = [];
  caseNotesTextList: any = [];
  followLoader=false;
  followVal:any = {follow_up:null};
  public tools: object = {
    type: 'MultiRow',
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', 'CreateLink', '|',
      'UnorderedList', 'OrderedList', '|',
      'Indent', 'Outdent', '|',
      'Undo', 'Redo', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'CreateTable',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|',
      'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      this.eventId = routeParams["id"];
      this.ticket_no = routeParams["ticket_no"];
      this.breadcrumbList = [
        {
          path: '/app/appointmentsList',
          name: 'Home'
        },
        {path: '/app/event-details/', name: 'Event Details'}
      ];
    })
    this.getTicketStatusList();
    this.getCandidateInfo();
    this.getApptDataInfo();
  }

  getTicketStatusList() {
    this.apiService.ticketStatusList({page: 0, limit: 20}).subscribe(
      (data: any) => {
        this.ticketStatusList = data;
      },
      (error: any) => {
        this.ticketStatusList = [];
      }
    );
  }

  saveAssignCase(e:any){
    this.assignLoader = true;
    this.apiService.statusToAssign({status:e.id,eventId:this.eventId}).subscribe(
      (data:any) => {
        this.assignLoader = false;
        this.toastr.info("Status Changed Successfully.", 'Success!!!',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      },
      (error:any)=>{
        this.assignLoader = false;
        this.toastr.error("Unable to change status", 'Error',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  getCandidateInfo() {
    this.apiService.getBasicData(this.eventId).subscribe(
      (data: any) => {
        this.basicData = data;
        this.assignCaseStatus = this.basicData.ticket_status;
      },
      (error: any) => {
        this.toastr.error("Unable to get details", 'Danger', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  savePurposeOfCall(){
    this.purposeLoader = true;
    this.apiService.casePurpose({purpose:this.eventsSummery.purpose,eventId:this.eventId}).subscribe(
      (data:any) => {
        this.purposeLoader = false;
        this.toastr.info("Saved Successfully.", 'Success!!!',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
        this.eventsSummery.purpose = null;
        this.getApptDataInfo();
      },
      (error:any)=>{
        this.purposeLoader = false;
        this.toastr.error("Unable to save", 'Error',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  saveNotes(){
    this.notesLoader = true;
    this.apiService.caseNotes({notes:this.eventsSummery.notes,eventId:this.eventId}).subscribe(
      (data:any) => {
        this.notesLoader = false;
        this.toastr.info("Saved Successfully.", 'Success!!!',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
        this.eventsSummery.notes = null;
        this.getApptDataInfo();
      },
      (error:any)=>{
        this.notesLoader = false;
        this.toastr.error("Unable to save", 'Error',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  getApptDataInfo(){
    this.casePurposeTextList=[];
    this.caseNotesTextList=[];
    this.apiService.getApptDetailsData({eventId:this.eventId}).subscribe(
      (data: any) => {
        this.apttData = data;
        this.apttData.forEach((val:any) =>  {
          if (val.purpose_of_call) {
            this.casePurposeTextList.push({data:JSON.parse(val.purpose_of_call),user:val.receiver,time:val.created_at});
          }
          if (val.notes) {
            this.caseNotesTextList.push({data:JSON.parse(val.notes),user:val.receiver,time:val.created_at});
          }
        });
      },
      (error: any) => {
        this.toastr.error("Unable to get data", 'Danger', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  followUpNotify(data:any){

    Swal.fire({
      title: 'Warning !!!',
      text: 'Are you sure you want to add follow-up?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, add it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {
        this.saveAnalytic(data);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.followVal={follow_up:null};
      }
    })
  }

  saveAnalytic(data:any){

    this.followLoader=true;
    var today = new Date();
    today.setHours( today.getHours() + Math.round(data.follow_up) );
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);
    data.follow_up = year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;

    data.eventId=this.eventId;
    this.apiService.saveCaseFollowData(data).subscribe(
      (data:any) => {
        this.toastr.success("Saved Successfully", 'Success!!!',{timeOut:3000,tapToDismiss: true,closeButton: true});
        this.followLoader=false;
        this.followVal = {follow_up:null};
        this.apiService.sendFollowUpRefreshEvent();
      },
      (error:any)=>{
        this.toastr.error("Unable to save.", 'Error',{timeOut:3000,tapToDismiss: true,closeButton: true});
        this.followLoader=false;
      });
  }

  checkZero(data:any){
    if(data!=undefined && data.toString().length == 1){
      data = "0" + data;
    }
    return data;
  }

  openVideoCallModal(){
    var url = window.location.href;
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2];
    this.videoCallLink = result + "/joinCall/join/" + this.ticket_no;
    $("#videoCallModal").modal("show");
  }

  clearVideoCallModal() {

  }

  closeVideoCallModal() {
    $("#videoCallModal").modal("hide");
  }

  copyVideoLink() {
    $("#" + this.videoCallLinkTextId).select();
    document.execCommand('copy');
    this.toastr.success("Link copied to clipboard.", 'Success', {
      timeOut: 3000,
      tapToDismiss: true,
      closeButton: true
    });
  }

}
