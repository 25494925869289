// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_GENERAL_URL: 'https://hl-api.3cubehealth.com/',
  version:'v.1.0',
  agoraAppID:'9a24bddef79e47d08b778543fbbafa75',
  firebaseConfig : {
		apiKey: "AIzaSyBpDP0fXM9QjSRy_keC9A5dRFnpi2g8RF8",
		authDomain: "videocall-faec5.firebaseapp.com",
		databaseURL: "https://videocall-faec5.firebaseio.com",
		projectId: "videocall-faec5",
		storageBucket: "videocall-faec5.appspot.com",
		messagingSenderId: "1028226968418",
		appId: "1:1028226968418:web:6818dee953ded086b19a3b"
	},
	firebaseLogin:{
		username:"dummyfirebase@test.com",
		password:"FirePass@88"
	},
	GENERAL_URL:'https://hl.3cubehealth.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
