import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HeadnavbarComponent} from './headnavbar/headnavbar.component';
import {AppointmentsComponent} from './appointments/appointments.component';
import {AddCandidateComponent} from './candidates/add-candidate/add-candidate.component';
import {ChildTokenGuard} from './child-token.guard';
import {EventDetailsComponent} from './event-details/event-details.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'app', component: HeadnavbarComponent, canActivateChild:[ChildTokenGuard], data: {breadcrumb: 'TopNavBar'}, children: [
      {
        path: 'appointmentsList', component: AppointmentsComponent
      },
      {
        path: 'appointmentsList/:search', component: AppointmentsComponent
      },
      {
        path: 'add-candidate', component: AddCandidateComponent
      },
      {
        path: 'edit-candidate/:id', component: AddCandidateComponent
      },
      {
        path: 'rebook-candidate/:id', component: AddCandidateComponent
      },
      {
        path: 'event-details/:id/:ticket_no', component: EventDetailsComponent
      },
      {path: 'videoCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
      ]
  },
  {path: 'joinCall', loadChildren: () => import('./video-call-lazy/video-call-lazy.module').then(m => m.VideoCallLazyModule)},
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
