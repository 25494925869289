import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {

  constructor(private router: Router,private apiService: ApiService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(req).pipe( tap(() => {},
			(err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status !== 401) {
						return;
					}
					localStorage.clear();
          localStorage.setItem("sessionexpire","true");
					this.router.navigate(['/login']);
				}
			}));
  }
}
