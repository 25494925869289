<header class="bg-danger"
        style="position: fixed;width: 100%;z-index: 410;box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);">
  <nav role="navigation" class="navbar topnavbar">
    <!-- START button offset-->

    <div class="btn-offset">
      <button style="display:none;" type="button" btn-layer-morph="" target="#layer-search" ripple=""
              class="btn btn-info btn-circle btn-lg shadow-z1">
        <em class="fa fa-search"></em>
      </button>
    </div>
    <!-- START button offset-->
    <!-- START navbar header-->
    <div class="navbar-header"><a id="logoId" [routerLink]="['/app/appointmentsList']"
                                  class="navbar-brand ml0 mr0 pr0 pl0" style="line-height: 0px;"><img
      src="assets/img/3cubelogo.jpg" alt="App Logo" style="width: 170px;height: 60px !important;"
      class="brand-logo"/></a>
      <!-- Mobile buttons-->
      <div class="mobile-toggles">
        <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
        <a href="" ng-click="app.sidebar.isOffscreen = !app.sidebar.isOffscreen" class="sidebar-toggle">
          <em class="fa fa-navicon"></em>
        </a>
        <!-- Button to show/hide the header menu on mobile. Visible on mobile only.-->
        <a href="" ng-click="header.toggleHeaderMenu()" class="menu-toggle hidden-material">
          <em class="fa fa-ellipsis-v fa-fw"></em>
        </a>
      </div>
    </div>
    <!-- END navbar header-->
    <!-- START Nav wrapper-->
    <div collapse="header.headerMenuCollapsed" class="nav-wrapper collapse navbar-collapse">
      <!-- START Left navbar-->
      <ul class="nav navbar-nav hidden-material">
        <li>
          <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
          <a style="display:none;" href="#" ng-click="app.sidebar.isOffscreen = !app.sidebar.isOffscreen"
             class="hidden-xs">
            <em ng-class="app.sidebar.isOffscreen ? 'fa-caret-right':'fa-caret-left'" class="fa"></em>
          </a>
        </li>
        <!-- START Apps menu-->
        <li class="dropdown">
          <a data-toggle="dropdown" class="dropdown-toggle" id="applicationsId">
            <em class="fa fa-th fa-fw"></em>
            <span class="visible-xs-inline ml">Applications</span>
          </a>
          <!-- START Dropdown menu-->
          <ul id="bg-white" class="dropdown-menu p wd-lg">
            <div class="row ml0 mr0 mt mb text-center">


              <div class="col-xs-4">
                <div class="pv">
                  <a id="apptRouteId" [routerLink]="['/app/appointmentsList']">
                           <span class="block mb">
                              <em class="fa fa-calendar fa-2x text-success"></em>
                           </span>
                    <small class="text-muted">Appointments</small>
                  </a>
                </div>
              </div>
            </div>
          </ul>
        </li>
        <li class="navbar-text">
          <span class="text-bold fs_14">Welcome, {{userName}}</span>
        </li>
        <li title="Today's Follow Up Case" class="dropdown" style="cursor: pointer;">
          <a data-toggle="modal" data-target="#followUpModalHeadBar" class="dropdown-toggle">
            <em class="fa fa-calendar"></em>
            <div class="label pull-right label-success">{{folloUpList.length}}</div>
            <span class="visible-xs-inline ml">Follow Up</span>
          </a>
        </li>
        <!-- END Apps menu-->
        <!-- START lock screen-->

      </ul>
      <ul class="nav navbar-nav hidden-material pull-right">
        <li>
          <a id="logOutId" (click)="logoutUser();" title="Logout" ripple="" style="cursor: pointer">
            <i class="fa fa-power-off"></i>
            <span class="visible-xs-inline ml">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
<section class="content" style="padding: 80px 10px 10px 10px;">
  <router-outlet></router-outlet>
</section>
<div class="footer">
  <p>© {{ currYear }} - Help Line - {{version}} </p>
</div>
<div class="modal fade" id="followUpModalHeadBar" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" id="btnCloseFollowUpModelHeadBar">&times;</button>
        <h4 class="modal-title mb0"> Today's Follow Up Case <button id="caseFollowUpRefreshBtn" (click)="caseFollowUpList()" class="btn btn-primary btn-xs mr pull-right" [disabled]="followupLoader"><i *ngIf="followupLoader" class="fa fa-spinner fa-spin mr"></i>Refresh</button></h4>
      </div>
      <div class="modal-body pt0">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>No.</th>
              <th>Ticket No.</th>
              <th>Time</th>
            </tr>
            </thead>
            <tbody *ngIf="folloUpList.length">
            <tr *ngFor="let data of folloUpList; let i = index">
              <td>{{i + 1}}</td>
              <td [routerLink]="['/app/event-details',data.id,data.ticket_no]" title="Case Details" style="cursor: pointer;" data-dismiss="modal">{{data.ticket_no}}</td>
              <td>{{data.followUpTime}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="!folloUpList.length">
            <tr>
              <td colspan="4" class="text-center">Data Not Found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
