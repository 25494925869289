<section class="content">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
        [class.active]="i===breadcrumbList.length-1">
      <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
        <b>{{ item.name }}</b>
      </a>
      <span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
    </li>
  </ol>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="col-sm-12 mb-xl" *ngIf="basicData">
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
            <tr class="bg-primary text-white">
              <td colspan="5" class="text-bold text-uppercase text-center">
                <span class="underline_Text">Candidate Details</span>
                <a title="Video Call" class="btn btn-xs btn-danger ml pull-right" id="scheduleCallLink" (click)="openVideoCallModal()"><i class="fa fa-video-camera mr"></i></a>
              </td>
            </tr>
            <tr>
              <td>NAME</td>
              <td>Age</td>
              <td>Email-ID</td>
              <td>Phone Number</td>
              <td>Ticket No</td>
            </tr>
            <tr>
              <td>
                <span class="text-uppercase">{{ basicData.f_name }} {{basicData.l_name }}</span>
              </td>
              <td>
                <span class="text-uppercase"> {{ basicData.age || '----'}}</span>
              </td>
              <td>
                <span> {{ basicData.email || '----' }}</span>
              </td>
              <td>
                <span> <span *ngIf="basicData.code">( {{ basicData.code }} ) </span> {{ basicData.mobile || '----'}}</span>
              </td>
              <td>
                <span class="text-uppercase">{{ ticket_no || '----'}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-4">
        <label class="control-label"> Case Status</label>
        <ng-select id="ticketStatusSelect" [items]="ticketStatusList" bindLabel="status" bindValue="id"
                   [clearable]="false"
                   placeholder="* Select status to assign..."
                   (change)="saveAssignCase($event)"
                   [(ngModel)]="assignCaseStatus" [ngModelOptions]="{standalone: true}">
          <ng-template ng-label-tmp let-item="item">
            {{item.status}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            {{item.status}}<br>
          </ng-template>
        </ng-select>
      </div>

       <div class="col-sm-offset-5 col-sm-3">
         <label class="control-label"> Case Follow-up</label>
         <form>
           <div class="">
             <div class="p0 col-sm-10">
               <input required="" min="1" type="number"
                      [(ngModel)]="followVal.follow_up"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Next Follow-Up ( In Hours )"
                      class="form-control" id="follow_up"/>
             </div>
             <div class="col-sm-2">
               <button (click)="followUpNotify(followVal)"
                       [disabled]="!followVal.follow_up"
                       class="mt btn btn-success btn-xs" id="btnFollowUpNotify"><i *ngIf="followLoader"
                                                                                   class="fa fa-spin fa-spinner"></i>
                 Save
               </button>
             </div>
           </div>
         </form>
       </div>
      <div class="col-sm-12 mt-lg">

        <ngb-accordion activeIds="static-1">
          <ngb-panel type="primary" id="static-1">
            <ng-template ngbPanelTitle>
              <b style="color: white;">Initial Call</b>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="panel">
                <div class="panel-body">
                  <div class="col-sm-12 mb">
                    <ejs-richtexteditor  [showCharCount]='true'
                                        [(ngModel)]="eventsSummery.purpose"
                                        [toolbarSettings]="tools"></ejs-richtexteditor>
                    <button (click)="savePurposeOfCall()" [disabled]="!eventsSummery.purpose || purposeLoader"
                            class="btn btn-success btn-sm mt" id="saveDailyNotesBtn"><i *ngIf="purposeLoader"
                                                                                        class="fa fa-spinner fa-spin"></i> Save
                    </button>
                  </div>
                  <div class="mt">
                    <ul>
                      <li *ngFor="let data of casePurposeTextList">
                        <strong>{{data.time | date : 'd/M/yy, h:mm a'}}</strong>
                        <span *ngIf="data.user">({{data.user}})</span>:
                        <br/>
                        <div [innerHTML]="data.data | safeHtml"></div>
                      </li>
                    </ul>
                    <ul *ngIf="!casePurposeTextList.length">
                      <li>Initial Call Details Not Found.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          </ngb-accordion>

        <br>

        <ngb-accordion>
          <ngb-panel type="primary">
            <ng-template ngbPanelTitle>
              <b style="color: white;">Follow up Call</b>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="panel">
                <div class="panel-body">
                  <div class="col-sm-12 mb">
                    <ejs-richtexteditor  [showCharCount]='true' [(ngModel)]="eventsSummery.notes"
                                        [toolbarSettings]="tools"></ejs-richtexteditor>
                    <button (click)="saveNotes()" [disabled]="!eventsSummery.notes || notesLoader"
                            class="btn btn-success btn-sm mt"><i *ngIf="notesLoader"
                                                                                        class="fa fa-spinner fa-spin"></i> Save
                    </button>
                  </div>
                  <div class="mt">
                    <ul>
                      <li *ngFor="let data of caseNotesTextList">
                        <strong>{{data.time | date : 'd/M/yy, h:mm a'}}</strong>
                        <span *ngIf="data.user">({{data.user}})</span>:
                        <br/>
                        <div [innerHTML]="data.data | safeHtml"></div>
                      </li>
                    </ul>
                    <ul *ngIf="!caseNotesTextList.length">
                      <li>Follow Up Call Details Not Found.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>

        </ngb-accordion>

      </div>

    </div>
  </div>
</section>
<div class="modal fade" id="videoCallModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="clearVideoCallModal();">&times;</button>
          <h4 class="modal-title"><b>Video Call</b></h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <label>Meeting Link:</label>
              <input type="text" class="form-control" [id]="videoCallLinkTextId" [value]="videoCallLink" readonly/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <br/>
              <button type="button" [disabled]="!ticket_no" class="btn btn-success" (click)="copyVideoLink();">Copy To Clipboard
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="text-align: left;">
          <a class="btn btn-primary" id="videoCallJoinLink" [routerLink]="['/app/videoCall/joinadmin',ticket_no]" (click)="closeVideoCallModal()" target="_blank">Join</a>
          <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="clearVideoCallModal();" id="btnCancelVideoCall">Cancel
          </button>
        </div>
    </div>
  </div>
</div>