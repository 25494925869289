<section class="content">
  <div  class="form-group row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div style="background-color: #586acc !important;" class="panel-heading">
          <h3 class="text-thin text-white text-center mt0 mb0">
            <strong style="font-size: 23px;"> Appointment List <button class="btn btn-success btn-xs ml" [routerLink]="['/app/add-candidate']"> Add Appointment</button></strong>
          </h3>
        </div>
        <div>
          <div class="col-sm-3 col-md-4 col-sm-offset-4 mt20">
            <input autofocus id="searchBox" placeholder="Search By Ticket No, Name, Company, Case Status" style="height: 40px;font-size: 16px;margin-bottom: 10px;" [formControl]="searchControl" [(ngModel)]="tableparams.search" class="form-control"/>
          </div>

          <table class="table table-striped table-bordered table-hover" >
            <thead>
            <tr>
              <th class="tableth_heading text-center">Company
              </th>
              <th class="tableth_heading text-center">Date Time
              </th>
              <th class="tableth_heading text-center">Ticket No
              </th>
              <th class="tableth_heading text-center">Name
              </th>

              <th class="tableth_heading text-center">Purpose
              </th>
              <th class="tableth_heading text-center">Status
              </th>
              <th class="tableth_heading text-center">Receiver
              </th>
              <th class="tableth_heading text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let eve of eventlist; let i = index" class="table_td_style">
              <td class="text-center text-uppercase">{{eve.comp_name || '-'}}</td>
              <td class="text-center text-uppercase">{{fixDate(eve.ticket_time)}}</td>
              <td class="text-center text-uppercase">{{eve.ticket_no}}</td>
              <td class="text-center">{{eve.f_name}} {{ eve.l_name }}</td>
              <td class="text-center text-uppercase">{{eve.purpose || '-'}}</td>
              <td class="text-center text-uppercase">{{eve.status}}</td>
              <td class="text-center text-uppercase">{{eve.receiver_name || '-'}}</td>
              <td class="text-center">
                <button id="viewId{{i}}" title="View" [routerLink]="['/app/event-details',eve.apptId,eve.ticket_no]" type="button"
                        class="h_w_25 p0 btn btn-circle btn-info mr">
                  <em class="fa fa-user-md"></em>
                </button>
                <button id="editId{{i}}" title="Edit" [routerLink]="['/app/edit-candidate',eve.apptId]" type="button"
                        class="h_w_25 p0 btn btn-circle btn-amber mr">
                  <em class="fa fa-pencil"></em>
                </button>
                <button id="rebookId{{i}}" title="Rebook" [routerLink]="['/app/rebook-candidate',eve.apptId]" type="button"
                        class="h_w_25 p0 btn btn-circle btn-purple mr">
                  <em class="fa fa-calendar"></em>
                </button>
                <button id="DeleteId{{i}}" title="Remove Appointment" (click)="deleteApptModal(eve.apptId,i)" type="button"
                        class="h_w_25 p0 btn btn-circle btn-danger">
                  <em class="fa fa-times"></em>
                </button>
              </td>
            </tr>
            <tr *ngIf="!eventlist.length &amp;&amp; !isLoading">
              <td colspan="8" class="text-center text-bold">Data Not Found.</td>
            </tr>
            <tr *ngIf="isLoading">
              <td colspan="8" class="text-center text-bold"><i aria-hidden="true" class="fa fa-spinner fa-spin"></i> Loading more events.</td>
            </tr>
            <tr *ngIf="!isLoading && !isListFinished">
              <td colspan="8" class="text-center text-bold">
                <button id="btnLoadMore" title="Load More" (click)="getEventList()" class="btn btn-primary">Load More</button>
              </td>
            </tr>
            </tbody>
          </table>
          <app-infinite-scroll (scrolled)="getEventList()" *ngIf="!isLoading">
          </app-infinite-scroll>
        </div>
      </div>
    </div>
  </div>
</section>
