import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-headnavbar',
  templateUrl: './headnavbar.component.html',
  styleUrls: ['./headnavbar.component.css']
})
export class HeadnavbarComponent implements OnInit {

  currYear:any = (new Date()).getFullYear();
  version= environment.version;
  userName: string= localStorage.getItem('userName');
  folloUpList: any = [];
  followupLoader = false;
  followUpRefresh:Subscription;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.followUpRefresh=this.apiService.getFollowUpRefreshEvent().subscribe(()=>{
      this.caseFollowUpList();
    })
  }

  ngOnInit(): void {
    this.caseFollowUpList();
  }

  logoutUser(){
    this.apiService.userLogOut().subscribe(
      (data)=>{
        localStorage.clear();
        this.router.navigate(['/login']);
      },
      (error)=>{
        this.toastr.error("Unable to log-out", 'Error',{
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    )
  }

  caseFollowUpList(){
    this.followupLoader = true;
    this.apiService.caseFollowUpList({followUpDateTime:new Date().toISOString()}).subscribe(data => {
      this.followupLoader = false;
      this.folloUpList = data;
    },(error:any)=>{
      this.followupLoader = false;
      this.folloUpList = [];
    });
  }

  ngOnDestroy() {
    this.followUpRefresh.unsubscribe();
  }

}
