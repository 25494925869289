import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../api.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-add-candidate',
  templateUrl: './add-candidate.component.html',
  styleUrls: ['./add-candidate.component.css']
})
export class AddCandidateComponent implements OnInit {

  breadcrumbList: any[] = [];
  candidateForm!: FormGroup;
  maxDate = new Date();
  receiveList: object = [];
  complist: any[] = [];
  relationList: object = [];
  purposeList: object = [];
  showLoader = false;
  basicData:any=null;
  id: any = null;
  action = "book";
  filteredCompany: Observable<any[]>;

  constructor(
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams['id'] == undefined ? null : routeParams['id'];
      this.breadcrumbList = [
        {
          path: '/app/appointmentsList',
          name: 'Home'
        }
      ];

      if (this.id == null) {
        this.breadcrumbList.push({path: '/app/add-Seafarer/', name: 'Add Appointment'});
      } else {
        if (this.activeRoute.snapshot.url[0].path == "edit-candidate") {
          this.action = "edit";
          this.breadcrumbList.push({path: '/app/edit-Seafarer/', name: 'Edit Appointment'});
        } else {
          this.action = "rebook";
          this.breadcrumbList.push({path: '/app/rebook-Seafarer/', name: 'Rebook Appointment'});
        }

      }
      this.candidateForm = this.formBuilder.group({
        f_name: [null, [Validators.required]],
        l_name: [null, [Validators.required]],
        age: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.maxLength(3),Validators.minLength(1)]],
        compId: [null],
        gender: [null, [Validators.required]],
        relationToSeafarer: [null],
        organization: [null],
        location: [null],
        receivedOn: [null],
        purpose: [null],
        emailId: ['', [Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,5}$/)]],
        code: ['', [Validators.pattern(/^\+[1-9\s-0-9]+$/),Validators.maxLength(6),Validators.minLength(2)]],
        telephone: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.maxLength(10),Validators.minLength(6)]],
      });
      this.companyList("");
      this.userRelationList();
      this.ticketReceivedList();
      this.ticketPurposeList();
      this.f.gender.setValue('Male');

      if(this.id !== null){
        this.getCandidateInfo();
      }
      this.filteredCompany = this.f.compId.valueChanges
        .pipe(
          startWith(''),
          map(company => company ? this._filterCompany(company) : this.complist.slice())
        );
    });
  }

  get f() {
    return this.candidateForm.controls;
  }

  companyList(search:any) {
    this.apiService.companyList({page: 0, limit: 200}).subscribe(
      (data: any) => {
        this.complist = data;
      },
      (error: any) => {
        this.complist = [];
      }
    );
  }

  userRelationList() {
    this.apiService.userRelationList({page: 0, limit: 200}).subscribe(
      (data: any) => {
        this.relationList = data;
      },
      (error: any) => {
        this.relationList = [];
      }
    );
  }

  ticketReceivedList() {
    this.apiService.ticketReceivedList({page: 0, limit: 200}).subscribe(
      (data: any) => {
        this.receiveList = data;
      },
      (error: any) => {
        this.receiveList = [];
      }
    );
  }

  ticketPurposeList() {
    this.apiService.ticketPurposeList({page: 0, limit: 200}).subscribe(
      (data: any) => {
        this.purposeList = data;
      },
      (error: any) => {
        this.purposeList = [];
      }
    );
  }

  getCandidateInfo() {
    this.apiService.getBasicData(this.id).subscribe(
      (data: any) => {
        this.basicData = data;
        this.f.f_name.setValue(this.basicData.f_name);
        this.f.l_name.setValue(this.basicData.l_name);
        this.f.gender.setValue(this.basicData.gender);
        this.f.relationToSeafarer.setValue(this.basicData.relation);
        this.f.location.setValue(this.basicData.location);
        this.f.age.setValue(this.basicData.age);
        this.f.emailId.setValue(this.basicData.email);
        this.f.code.setValue(this.basicData.code);
        this.f.telephone.setValue(this.basicData.mobile);
        if(this.action == "edit"){
         // this.f.compId.setValue(this.basicData.comp_id);
          this.f.organization.setValue(this.basicData.organization);
          this.f.receivedOn.setValue(this.basicData.received_on);
          this.f.purpose.setValue(this.basicData.purpose);
        }
        if (this.basicData.comp_name) {
          this.f.compId.setValue({comp_name: this.basicData.comp_name, id: this.basicData.comp_id})
        }
      },
      (error: any) => {
        this.toastr.error("Unable to get details", 'Danger', {
          timeOut:3000,
          tapToDismiss: true,
          closeButton: true
        });
      }
    );
  }

  saveCrew() {
    if (this.candidateForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      if(this.id == null){
        let reqData = {
          "f_name": this.f.f_name.value,
          "l_name": this.f.l_name.value,
          "gender": this.f.gender.value,
          "location": this.f.location.value,
          "relationToSeafarer": this.f.relationToSeafarer.value,
          "organization": this.f.organization.value,
          "compId": this.f.compId.value,
          "receivedOn": this.f.receivedOn.value,
          "purpose": this.f.purpose.value,
          "code": this.f.code.value,
          "email": this.f.emailId.value,
          "mobile": this.f.telephone.value,
          "age": this.f.age.value

        }
        this.apiService.createCustomEvent(reqData).subscribe(
          (data:any) => {
            this.toastr.success("Event generated successfully.", 'Success!!!', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.router.navigate(['/app/appointmentsList']);
          },
          (error:any) => {
            this.toastr.error("Error while generating event", 'Danger', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.showLoader = false;
          }
        );
      }else if(this.action == "edit") {
        let updateData = {
          "f_name": this.f.f_name.value,
          "l_name": this.f.l_name.value,
          "gender": this.f.gender.value,
          "location": this.f.location.value,
          "relationToSeafarer": this.f.relationToSeafarer.value,
          "organization": this.f.organization.value,
          "compId": this.f.compId.value,
          "receivedOn": this.f.receivedOn.value,
          "purpose": this.f.purpose.value,
          "u_id": this.basicData.u_id,
          "code": this.f.code.value,
          "email": this.f.emailId.value,
          "mobile": this.f.telephone.value,
          "age": this.f.age.value

        }
        this.apiService.updateEvent(updateData,this.id).subscribe(
          (data:any) => {
            this.toastr.success("Event edited successfully.", 'Success!!!', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.router.navigate(['/app/appointmentsList']);
          },
          (error:any) => {
            this.toastr.error("Error while generating event", 'Danger', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.showLoader = false;
          }
        );
      }else{
        let data = {
          "f_name": this.f.f_name.value,
          "l_name": this.f.l_name.value,
          "gender": this.f.gender.value,
          "location": this.f.location.value,
          "relationToSeafarer": this.f.relationToSeafarer.value,
          "organization": this.f.organization.value,
          "compId": this.f.compId.value,
          "receivedOn": this.f.receivedOn.value,
          "purpose": this.f.purpose.value,
          "u_id": this.basicData.u_id,
          "code": this.f.code.value,
          "email": this.f.emailId.value,
          "mobile": this.f.telephone.value,
          "age": this.f.age.value

        }
        this.apiService.rebookEvent(data).subscribe(
          (data:any) => {
            this.toastr.success("Event rebooked successfully.", 'Success!!!', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.router.navigate(['/app/appointmentsList']);
          },
          (error:any) => {
            this.toastr.error("Error while rebooking event", 'Danger', {
              timeOut:3000,
              tapToDismiss: true,
              closeButton: true
            });
            this.showLoader = false;
          }
        );
      }
    }
  }

  GetDateStringFromPickerDate(date:any) {
    if (date == "" || date == null || date == undefined) {
      return null;
    }
    var dateStr = date.toString();
    var dateObj = new Date(dateStr);
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    var newDate = day + "-" + month + "-" + year;
    return newDate;
  }

  getCompanyText(option:any) {
    if (option != null) {
      if (option.comp_name != null) {
        return option.comp_name.toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  private _filterCompany(value: string): any[] {
    if (typeof value != 'string') {
      try {
        value = String(value["comp_name"]);
      } catch (err) {
        value = "";
      }

    }
    const filterValue = value.toLowerCase();
    this.companyList(value);
    return this.complist.filter(state => state.comp_name.toString().toLowerCase().indexOf(filterValue) > -1);
  }


}
