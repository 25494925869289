import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: any = {email: '', password: ''};
  year: number = new Date().getFullYear();
  ScreenHeight: number = 0;
  loadershow: boolean = false;
  loginErrorshow: boolean = false;
  loginFormErr: string = '';

  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('tokenKey') != null && localStorage.getItem('tokenKey') != undefined) {
      this.router.navigate(['/app/appointmentsList']);
    }
    setTimeout(() => {
      this.ScreenHeight = (window.innerHeight);
    }, 0);
  }

  submitForm(user: any) {
    this.loadershow = true;
    this.apiService.loginAPI(user).subscribe(
      (data: any) => {
        localStorage.setItem('tokenKey', 'Bearer ' + data['data']['token']);
        localStorage.setItem('userName', data['data']['name']);
        this.router.navigate(['/app/appointmentsList']);
      },
      (error: any) => {
        this.loadershow = false;
        this.loginErrorshow = true;
        this.loginFormErr = 'Email-Id Or Password is incorrect.';
        setTimeout(() => {
          this.loginErrorshow = false;
        }, 5000);
      }
    );
  }

}
